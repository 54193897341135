import * as React from "react"
import { navigate } from "gatsby"

import useWindowSize from "../hooks/useWindowSize"

interface Props {
  title: string
  iframeSrc: string
}

const Layout = (props: Props) => {
  const size = useWindowSize()
  const [showHint, setShowHint] = React.useState(true)

  return (
    <>
      <div className="flex flex-col h-screen min-h-screen">
        <div className="bg-primary h-10 backdrop-filter backdrop-blur-xs bg-opacity-80">
          <div className="flex justify-between p-2 px-4">
            <h2 className="text-sm tracking-wider font-semibold">
              {props.title}
            </h2>
            <svg width={24} viewBox="0 0 512 512" onClick={() => navigate(-1)}>
              <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
            </svg>
          </div>
        </div>
        <div className="flex-1 relative">
          {showHint && (
            <div
              className="absolute inset-0 z-10  cursor-grab text-center flex flex-col justify-center items-center bg-black text-white backdrop-filter backdrop-blur-xs bg-opacity-70"
              onClick={() => setShowHint(false)}
            >
              <div>Click on the floor to walk to the location</div>
              <div className="text-xs text-white/50 mt-2">
                Click to dismiss this message
              </div>
            </div>
          )}
          <iframe
            className="w-full"
            style={{ height: `calc(${size.height}px - 2.5rem)` }}
            src={props.iframeSrc}
          />
        </div>
      </div>
    </>
  )
}

export default Layout
